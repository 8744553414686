import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import CoreuiVue from '@coreui/vue'
import VCalendar from 'v-calendar'
import { iconsSet as icons } from './assets/icons/icons.js'
import VMdDateRangePicker from 'v-md-date-range-picker'
import VueAnalytics from 'vue-analytics'
import 'regenerator-runtime'
import firebase from 'firebase/app'
import './firebaseInit'
import i18n from './plugins/i18n'
import VueCarousel from 'vue-carousel';
import * as Sentry from "@sentry/vue";
import * as VueGoogleMaps from 'vue2-google-maps';
import VueLazyload from 'vue-lazyload';

Vue.config.performance = true
Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VCalendar)
Vue.use(VueCarousel);


Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: process.env.VUE_APP_NOIMAGE,

  attempt: 1
});


Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    async: true,
    defer: true,
  },
});

Vue.use(VMdDateRangePicker)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ANALYTICS,
  router,
})

let app = ''

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      icons,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  }
})


Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration(),

  ],
  trackComponents: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", process.env.VUE_APP_WEB_URL, process.env.VUE_APP_AUTH_URL, process.env.VUE_APP_SERVICE_URL, process.env.VUE_APP_API_URL, process.env.VUE_APP_CRM_API_URL, process.env.VUE_APP_PAYMENT_GATEWAY_URL, process.env.VUE_APP_MESSAGE_CALLSTAFF, /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


