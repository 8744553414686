import Vue from 'vue'
import Router from 'vue-router'

/* ROUTER FOR WEB-PORTAL */

/* CONTAINER */
const TheContainer = () => import('@/containers/TheContainer')
const TheMenuContainer = () => import('@/containers/TheMenuContainer')
const TheDeliveryContainer = () => import('@/containers/TheDeliveryContainer')

const Home = () => import('@/views/Home')

/* AUTHENTICATION */
const Login = () => import('@/views/login/Login')
const Logout = () => import('@/views/users/Logout')
const ForgotPassword = () => import('@/views/login/ForgotPassword')
const PageNotFound = () => import('@/views/404')
const TokenExpire = () => import('@/views/403')
const ErrorPage = () => import('@/views/401')
const OneLogin = () => import('@/views/login/OneLogin')
const CashierLogin = () => import('@/views/login/CashierLogin')
const CashierResetPassword = () => import('@/views/login/CashierResetPassword')

/* SHOP */
const Shop = () => import('@/views/shop/Shop')

/* USER */
const User = () => import('@/views/users/User')

/* GENERATE QRCODE */
const QRCodeForPickUp = () => import('@/views/qrcode/PickUp')
const QRCodeForDineIn = () => import('@/views/qrcode/DineIn')

/* MENU */
const MenuList = () => import('@/views/menu/Lists')
const MenuDetail = () => import('@/views/menu/Detail')
const MenuByCategory = () => import('@/views/menu/ByCategoryLists')
const MyCart = () => import('@/views/menu/MyCart')
const PlaceOrder = () => import('@/views/menu/PlaceOrder')
const Address = () => import('@/views/menu/Address')
const OrderReceived = () => import('@/views/menu/OrderReceived')
const OrderPending = () => import('@/views/menu/Pending')

/* PAYMENT */
const Payment = () => import('@/views/payment/Index')
const CreditCard = () => import('@/views/payment/CreditCard')
const Promptpay = () => import('@/views/payment/Promptpay')
const InternetBanking = () => import('@/views/payment/InternetBanking')

/* PICK UP */
const PickUpMenuLists = () => import('@/views/pickup/Lists')
const PickUpMenuDetail = () => import('@/views/pickup/Detail')
const PickUpMenuByCategory = () => import('@/views/pickup/ByCategoryLists')
const PickUpMyCart = () => import('@/views/pickup/MyCart')
const PickUpPlaceOrder = () => import('@/views/pickup/PlaceOrder')
const PickUpOrderPending = () => import('@/views/pickup/Pending')
const OrderSuccess = () => import('@/views/pickup/Success')
const OrderFailure = () => import('@/views/pickup/Failure')

/* DINE IN */
const DineInHome = () => import('@/views/dine-in/Home')
const DineInMenuLists = () => import('@/views/dine-in/Lists')
const DineInMenuDetail = () => import('@/views/dine-in/Detail')
const DineInMyCart = () => import('@/views/dine-in/MyCart')
const DineInPending = () => import('@/views/dine-in/Pending')
const DineInSelectedItem = () => import('@/views/dine-in/SelectedItem')
const DineInCallStaff = () => import('@/views/dine-in/CallStaff')
const DineInPaymentGateway = () => import('@/views/dine-in/payment/PaymentGateway')
const DineInQRPayment = () => import('@/views/dine-in/payment/QRPayment')
const DineInPendingPayment = () => import('@/views/dine-in/payment/PendingPayment')

/* Delivery */
// const DeliveryHome = () => import('@/views/delivery/Home')
// const DeliveryMenulists = () => import('@/views/delivery/Menulists')
// const DeliveryMyCart = () => import('@/views/delivery/MyCart')
// const DeliveryUser = () => import('@/views/delivery/User')
// const DeliveryHistory = () => import('@/views/delivery/History')
// const DeliveryUserDetails = () => import('@/views/delivery/UserDetails')
// const DeliveryUserAddresses = () => import('@/views/delivery/UserAddresses')
// const DeliveryTrackingOrder = () => import('@/views/delivery/TrackingOrder')
// const DeliveryPaymentGateway = () => import('@/views/delivery/PaymentGateway')
// const DeliveryOrderDetails = () => import('@/views/delivery/OrderDetails')
// const DeliveryNotification = () => import('@/views/delivery/Notification')
// const DeliveryShopMapper = () => import('@/views/delivery/ShopMapper')

/* ------------------------------------------------------------------------------------ */

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
})

function configRoutes() {
  return [
    {
      path: '*',
      component: PageNotFound,
    },
    {
      path: '/texp',
      component: TokenExpire,
    },
    {
      path: '/error',
      component: ErrorPage,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/one-login',
      name: 'OneLogin',
      component: OneLogin,
    },
    {
      path: '/cashier-login',
      name: 'CashierLogin',
      component: CashierLogin,
    },
    {
      path: '/cashier/resetpassword',
      name: 'CashierResetPassword',
      component: CashierResetPassword,
    },
    {
      path: '/',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'QRCodeForDineIn',
          component: QRCodeForDineIn,
        },
        {
          path: '/shop',
          name: 'Shop',
          component: Shop,
        },
        {
          path: '/user',
          name: 'User',
          component: User,
        },
        {
          path: '/user/logout',
          name: 'Logout',
          component: Logout,
        },
      ],
    },
    /* {
      path: '/qrcode',
      name: 'QRCode',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'pickup',
          name: 'QRCodeForPickUp',
          component: QRCodeForPickUp,
        },
        {
          path: 'dine-in',
          name: 'QRCodeForDineIn',
          component: QRCodeForDineIn,
        },
      ],
    }, */
    /* {
      path: '/menu',
      component: TheMenuContainer,
      children: [
        {
          path: '',
          name: 'MenuList',
          component: MenuList,
        },
        {
          path: ':objectId/detail',
          name: 'MenuDetail',
          component: MenuDetail,
        },
        {
          path: ':categoryObjectId/category',
          name: 'MenuByCategory',
          component: MenuByCategory,
        },
        {
          path: 'mycart',
          name: 'MyCart',
          component: MyCart,
        },
        {
          path: 'order',
          name: 'PlaceOrder',
          component: PlaceOrder,
        },
        {
          path: 'address',
          name: 'Address',
          component: Address,
        },
        {
          path: 'order-received',
          name: 'OrderReceived',
          component: OrderReceived,
        },
        {
          path: 'pending',
          name: 'OrderPending',
          component: OrderPending,
        },
      ],
    }, */
    /* {
      path: '/payment',
      component: TheMenuContainer,
      children: [
        {
          path: '',
          name: 'Payment',
          component: Payment,
        },
        {
          path: 'credit-card',
          name: 'CreditCard',
          component: CreditCard,
        },
        {
          path: 'promptpay',
          name: 'Promptpay',
          component: Promptpay,
        },
        {
          path: 'internet-banking',
          name: 'InternetBanking',
          component: InternetBanking,
        },
      ],
    }, */
    /* {
      path: '/pickup',
      component: TheMenuContainer,
      children: [
        {
          path: 'menulists',
          name: 'PickUpMenuLists',
          component: PickUpMenuLists,
        },
        {
          path: ':objectId/detail',
          name: 'PickUpMenuDetail',
          component: PickUpMenuDetail,
        },
        {
          path: ':categoryObjectId/category',
          name: 'PickUpMenuByCategory',
          component: PickUpMenuByCategory,
        },
        {
          path: 'mycart',
          name: 'PickUpMyCart',
          component: PickUpMyCart,
        },
        {
          path: 'place-order',
          name: 'PickUpPlaceOrder',
          component: PickUpPlaceOrder,
        },
        {
          path: 'pending',
          name: 'PickUpOrederPending',
          component: PickUpOrderPending,
        },
        {
          path: 'failure',
          name: 'OrderFailure',
          component: OrderFailure,
        },
        {
          path: 'success',
          name: 'OrderSuccess',
          component: OrderSuccess,
        },
      ],
    }, */
    // {
    //   path: '/delivery',
    //   component: TheDeliveryContainer,
    //   children: [
    //     {
    //       path: '/delivery/home',
    //       name: 'DeliveryHome',
    //       component: DeliveryHome,
    //     },
    //     {
    //       path: '/delivery/menulists',
    //       name: 'DeliveryMenulists',
    //       component: DeliveryMenulists,
    //     },
    //     {
    //       path: '/delivery/MyCart',
    //       name: 'DeliveryMyCart',
    //       component: DeliveryMyCart,
    //     },
    //     {
    //       path: '/delivery/user',
    //       name: 'DeliveryUser',
    //       component: DeliveryUser,
    //     },
    //     {
    //       path: '/delivery/history',
    //       name: 'DeliveryHistory',
    //       component: DeliveryHistory,
    //     },
    //     {
    //       path: '/delivery/user/details',
    //       name: 'DeliveryUserDetails',
    //       component: DeliveryUserDetails,
    //     },
    //     {
    //       path: '/delivery/user/addresses',
    //       name: 'DeliveryUserAddresses',
    //       component: DeliveryUserAddresses,
    //     },
    //     {
    //       path: '/delivery/tracking',
    //       name: 'DeliveryTrackingOrder',
    //       component: DeliveryTrackingOrder,
    //     },
    //     {
    //       path: '/delivery/order-details',
    //       name: 'DeliveryOrderDetails',
    //       component: DeliveryOrderDetails,
    //     },
    //     {
    //       path: '/delivery/notification',
    //       name: 'DeliveryNotification',
    //       component: DeliveryNotification,
    //     },
    //     {
    //       path: '/delivery/payment-gateway',
    //       name: 'DeliveryPaymentGateway',
    //       component: DeliveryPaymentGateway,
    //     },
    //     // Add dynamic route with a parameter

    //   ],
    // },
    // {
    //   path: '/delivery/:param',
    //   name: 'DeliveryShopMapper',
    //   component: DeliveryShopMapper, // Your Vue component for this dynamic route
    // },

    // {
    //   path: '/dine-in/home',
    //   name: 'DineInHome',
    //   component: DineInHome,
    // },
    {
      path: '/dine-in',
      component: TheMenuContainer,
      children: [
        {
          path: 'home',
          name: 'DineInHome',
          component: DineInHome,
        },
        {
          path: 'menulists',
          name: 'DineInMenuLists',
          component: DineInMenuLists,
        },
        {
          path: ':objectId/detail',
          name: 'DineInMenuDetail',
          component: DineInMenuDetail,
        },
        {
          path: 'mycart',
          name: 'DineInMyCart',
          component: DineInMyCart,
        },
        {
          path: 'paymentgateway',
          name: 'DineInPaymentGateway',
          component: DineInPaymentGateway,
        },
        {
          path: 'pending/payment',
          name: 'DineInPendingPayment',
          component: DineInPendingPayment,
        },
        {
          path: 'qrpayment',
          name: 'DineInQRPayment',
          component: DineInQRPayment,
        },
        {
          path: 'selecteditem',
          name: 'DineInSelectedItem',
          component: DineInSelectedItem,
        },
        {
          path: 'callstaff',
          name: 'DineInCallStaff',
          component: DineInCallStaff,
        },
        {
          path: 'pending',
          name: 'DineInPending',
          component: DineInPending,
        },
      ],
    },
  ]
}

import jwt from 'jsonwebtoken'

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (to.query.token === undefined) {
      //เข้าระบบปกติ
      if (!localStorage.getItem('token')) {
        next('/login')
      }
      next()
    } else {
      if (to.query.token !== undefined) {
        const accessToken = to.query.token
        let uid = ''
        let uiddecode = jwt.decode(accessToken)

        if (uiddecode) {
          uid = uiddecode.uid
        }

        localStorage.setItem('token', accessToken)
        localStorage.setItem('uid', uid)
        window.location.href = '/'
      } else {
        window.location.href = '/login'
      }
    }
  }
  next()
})

export default router
